@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0px !important;
  padding: 0;
  font-family: "Montserrat", sans-serif;
}

::-webkit-scrollbar {
  width: 4px;
  height: 80px;
}

::-webkit-scrollbar-track {
  background: #222a35;
}

::-webkit-scrollbar-thumb {
  background: #575c66;
  border-radius: 6px;
}

::-webkit-scrollbar-thumb:hover {
  background: #626970;
}

.wave {
  animation-name: wave-animation;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  transform-origin: 70% 70%;
  display: inline-block;
}

@keyframes wave-animation {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(14deg);
  }
  20% {
    transform: rotate(-8deg);
  }
  30% {
    transform: rotate(14deg);
  }
  40% {
    transform: rotate(-4deg);
  }
  50% {
    transform: rotate(10deg);
  }
  60% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
